<template>
    <v-card :loading="loading">
        <v-card-title>
            <v-btn small icon @click="close" color="primary" class="mr-2">
                <v-icon>
                    mdi-close
                </v-icon>
            </v-btn>
            <h3>
                {{ title }}
            </h3>
        </v-card-title>
        <v-divider class="ml-4 mr-5" />
        <v-card-text>
            <v-form v-model="valid">
                <v-row no-gutters>
                    <v-col cols="12">
                        <v-combobox
                            v-model="workOrderType"
                            :items="typeOptions"
                            hide-details
                            prepend-icon="mdi-format-list-bulleted-type"
                            hide-selected
                            prefix="Type: "
                            required
                            flat
                            :rules="[rules.required]"
                            class="mt-2"
                            @change="setTypeInfo"
                        />
                    </v-col>
                </v-row>
                <v-row no-gutters v-if="workOrderType == typeOptions[2]">
                    <v-col cols="12">
                        <v-combobox
                            v-model="selectedWorkOrder"
                            :items="approvedWOs"
                            item-text="code"
                            hide-details
                            prepend-icon="mdi-tools"
                            prefix="Work Order: "
                        >
                        </v-combobox>
                    </v-col>
                    <v-col cols="12">
                        <v-row no-gutters class="mt-4">
                            <span
                                >If you can't find the work order, search for it
                                by its code.</span
                            >
                        </v-row>
                        <v-row no-gutters>
                            <v-col cols="12" xl="6" lg="6" md="6">
                                <v-text-field
                                    v-model="woCode"
                                    prefix="Code: "
                                    prepend-icon="mdi-database-search"
                                    hide-details
                                />
                            </v-col>
                            <v-col cols="12" xl="6" lg="6" md="6">
                                <v-btn
                                    :loading="loading"
                                    color="primary"
                                    rounded
                                    :disabled="!woCode"
                                    @click="searchWO"
                                    class="mt-3"
                                >
                                    SEARCH
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row no-gutters v-else>
                    <v-col cols="12">
                        <v-combobox
                            v-if="!!projectId"
                            v-model="workOrder.project"
                            :items="projects"
                            item-text="name"
                            hide-details
                            prepend-icon="mdi-account-hard-hat"
                            prefix="Project: "
                            :disabled="!!projectId"
                        >
                        </v-combobox>
                        <v-combobox
                            v-else
                            v-model="workOrder.project"
                            :items="projects"
                            item-text="name"
                            hide-details
                            prepend-icon="mdi-account-hard-hat"
                            prefix="Project: "
                            :rules="[rules.required]"
                        >
                        </v-combobox>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="12">
                        <v-textarea
                            v-model="workOrder.notes"
                            prepend-icon="mdi-text"
                            prefix="Notes: "
                            hide-details
                            rows="2"
                        >
                        </v-textarea>
                    </v-col>
                </v-row>
                <v-row
                    no-gutters
                    v-if="workOrderType == typeOptions[2]"
                    class="pb-0 mb-0"
                >
                    <p class="mt-6 mr-2 mb-0">Warranty:</p>
                    <v-checkbox v-model="warranty" hide-details></v-checkbox>
                </v-row>
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-spacer />
            <v-btn
                color="primary"
                class="mb-4"
                @click="create"
                rounded
                :loading="loading"
                :disabled="
                    !valid ||
                        (!selectedWorkOrder && workOrderType == typeOptions[2])
                "
            >
                {{ buttonText }}
            </v-btn>
        </v-card-actions>
        <!--Reprocess Form-->
        <v-dialog
            :retain-focus="false"
            v-model="reprocessForm"
            persistent
            max-width="960px"
        >
            <ReprocessForm
                v-if="reprocessForm"
                :workOrder="selectedWorkOrder"
                :warranty="warranty"
                @closeDialog="closeReprocessForm"
                @closeSuccess="newReprocessWO"
            />
        </v-dialog>
    </v-card>
</template>

<script>
import { mapMutations } from 'vuex'
import API from '@/services/api'

export default {
    name: 'WorkOrderForm',
    components: {
        ReprocessForm: () =>
            import('@/components/WorkOrders/ReprocessForm.vue'),
    },
    props: {
        projectId: {
            type: String,
            deafult: () => '',
        },
        project: {
            type: Object,
            deafult: () => ({}),
        },
        workOrders: {
            type: Array,
            deafult: () => [],
        },
    },
    data: () => ({
        woCode: null,
        warranty: false,
        loading: false,
        workOrder: {},
        projects: [],
        workOrderType: 'Production',
        typeOptions: ['Production', 'Assembly', 'Reprocess'],
        rules: {
            required: v => !!v || 'Required',
        },
        valid: false,
        title: '',
        buttonText: '',
        reprocessForm: false,
        selectedWorkOrder: undefined,
    }),
    async mounted() {
        try {
            this.loading = true
            if (this.project && this.project.id) {
                this.projects = [this.project]
                this.workOrder.project = this.project
            } else {
                this.projects = await API.getLiteProjects({})
            }
            this.approvedWOs = this.workOrders.filter(
                wo => wo.status == 'approved' || wo.status == 'finished'
            )
            this.setTypeInfo()
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        async searchWO() {
            try {
                this.loading = true
                this.selectedWorkOrder = await API.findWOByCode(
                    this.woCode.toUpperCase()
                )
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async create() {
            try {
                this.loading = true
                if (this.workOrderType == this.typeOptions[2]) {
                    this.reprocessForm = true
                } else {
                    await API.createWorkOrder({
                        projectId: this.workOrder.project.id,
                        notes: this.workOrder.notes,
                        assemblyWorkOrder:
                            this.workOrderType == this.typeOptions[1],
                    })
                    this.$emit('addWorkOrder')
                    this.$emit('close')
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        close() {
            this.$emit('close')
        },
        async setTypeInfo() {
            try {
                this.loading = true
                if (this.workOrderType == this.typeOptions[1]) {
                    this.title = 'New Assembly Work Order'
                    this.buttonText = 'CREATE NEW ASSEMBLY ORDER'
                } else if (this.workOrderType == this.typeOptions[2]) {
                    let currentDate = new Date()
                    let initialDate = new Date(currentDate)
                    initialDate.setMonth(initialDate.getMonth() - 3)
                    initialDate.setHours(0, 0, 0, 0)
                    const closedOrders = await API.getClosedWorkOrders({
                        startDate: initialDate.toISOString().split('T')[0],
                        endDate: currentDate.toISOString().split('T')[0],
                    })
                    this.approvedWOs = [...this.approvedWOs, ...closedOrders]
                    this.title = 'New Reprocess Work Order'
                    this.buttonText = 'CREATE NEW REPROCESS ORDER'
                } else {
                    this.title = 'New Work Order'
                    this.buttonText = 'CREATE NEW ORDER'
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        closeReprocessForm() {
            this.reprocessForm = false
        },
        newReprocessWO() {
            this.$emit('addWorkOrder')
            this.$emit('close')
        },
    },
}
</script>

<style></style>
